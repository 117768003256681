body {
  margin: 0;
  padding: 0;
  background-image: linear-gradient(to bottom, #f5f5f5, #ffffff);
  width: 100vw;
}
/* ### Reset ### */
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 100%;
  font: inherit;
  list-style: none;
  vertical-align: baseline;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

/* HTML5 display definitions
   Corrects block display not defined in IE6/7/8/9 & FF3 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
main,
nav,
section,
summary {
  display: block;
}

/* Corrects inline-block display not defined in IE6/7/8/9 & FF3 */
audio,
canvas,
video {
  display: inline-block;
}

/* Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet */
button,
input {
  /* line-height: normal; */
}

/* Addresses appearance set to searchfield in Safari 5, Chrome */
input[type='search'] {
  -webkit-appearance: textfield;
}

/* Removes inner padding and search cancel button in S5, Chrome on OS X */
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* Removes inner padding and border in FF3+ */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: none;
  padding: 0;
}

textarea {
  vertical-align: top;
}

button,
input[type='submit'],
input[type='search'],
input[type='text'] {
  background: none;
  border: none;
  outline: 0;
}

img {
  max-width: 100%;
  height: auto;
}

input:focus,
textarea:focus {
  outline: 0;
}

input {
  margin: 0;
  padding: 0;
}

pre {
  white-space: pre-wrap;
}

form {
  width: 100%;
}
